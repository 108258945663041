
















































import { Component, Vue } from 'vue-property-decorator'
import ws, { SocketMsg } from '../../services/socket'
import { PortfolioMessage } from '@/services/data'
import utility from '@/common/utility'
import NumberRowEditor from '@/components/NumberRowEditor.vue'
import checkListService, { StockOptionCheckItem } from '@/services/checkListService'

@Component({
  components: {
    NumberRowEditor
  }
})

export default class OptCheckList extends Vue {
  checkList: StockOptionCheckItem[] = []
  showSettingDialog = false
  currentCheckRow: StockOptionCheckItem = null
  editingTarget: number

  async mounted () {
    ws.Local.init()
    const res = await checkListService.getOptCheckList()
    this.checkList = res.Result
    ws.Local.addMessageCallBack(this.msgCallBack)
    ws.Local.sendContext({ MsgId: utility.getUUInt(), Action: 'reqAccountUpdates' })
  }

  setTargetNumber (row: StockOptionCheckItem) {
    this.showSettingDialog = true
    this.currentCheckRow = row
    this.editingTarget = row.UserTarget
  }

  async targetSaved () {
    const res = await checkListService.setTargetAsync({
      SymbolId: this.currentCheckRow.SymbolId,
      Target: this.editingTarget
    })
    if (res.Error) {
      await this.$alert(res.Error)
    } else {
      this.currentCheckRow.UserTarget = this.editingTarget
      this.showSettingDialog = false
    }
  }

  destroyed (): void {
    ws.Local.removeMessageCallBack(this.msgCallBack)
  }

  async msgCallBack (msg: SocketMsg) {
    if (msg.Action === 'resPortfolioUpdated') {
      console.log('#TODO')
    }
  }
}
